import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MfeLoader } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeDeviceDetails = (props) => {
  const { stack, shell, deviceDetailsMfe, localization } = useShellRootContext();

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <div data-testid={'sub-mfe-wrapper'}>
          <MfeLoader
            disableContainerPadding
            {...props}
            {...{
              component,
              type,
              stack,
              shell,
              localization,
            }}
          />
        </div>
      );
    },
    [shell, stack, props],
  );

  const memoRenderSubMfe = useMemo(() => {
    return (
      <div className="subMfeDeviceDetails">
        <SubMfe
          type="ECPDeviceDetails"
          component={
            deviceDetailsMfe !== '' ? deviceDetailsMfe : '@jarvis/react-ecp-device-details'
          }
        />
      </div>
    );
  }, []);

  return <>{memoRenderSubMfe}</>;
};

SubMfeDeviceDetails.defaultProps = {
  shell: {},
  stack: null,
};

SubMfeDeviceDetails.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

export default SubMfeDeviceDetails;
