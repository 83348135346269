import React from 'react';
import ShellRootContext from './ShellRootContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ShellRootProvider = (props) => {
  const flags = useFlags();
  return (
    <ShellRootContext.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55,
        stack: props.stack ?? 1, // 1 is the pie stack
        shell: props.shell,
        localization: props.localization,
        events: props.events,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        navigation: props.navigation,
        getBreadcrumb: props.getBreadcrumb,
        setBreadcrumb: props.setBreadcrumb,
        removeBreadcrumb: props.removeBreadcrumb,
        showDevicesMainHeader: props.showDevicesMainHeader,
        deviceDetailsMfe: props.deviceDetailsMfe,
        deviceDetailsComponent: props.deviceDetailsComponent,
        customRelativePath: props.customRelativePath,
        theme: props.theme,
        featureFlags: flags,
      }}
    >
      {props.children}
    </ShellRootContext.Provider>
  );
};
