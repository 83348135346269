import React, { useEffect, useState } from 'react';
import rootReducer from 'src/store';
import packageInfo from '../package.json';
import { Provider } from 'react-redux';
import { TShellProps } from '../src/types/TShellProps';
import { configureStore } from '@reduxjs/toolkit';
import { ShellRootProvider } from '../src/contexts/ShellRoot';
import { ToastProvider, useToast, DirectionProvider } from '@veneer/core';
import { App } from '../src';
import CustomThemeProvider from '../src/contexts/CustomThemeProvider/CustomThemeProvider';
import { currentStack } from '../src/utils/constants';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

declare global {
  interface Window {
    Shell: TShellProps;
  }
}

declare const window: any;
declare const sessionStorage: any;

export default function Root(props) {
  const { v1 } = window.Shell as TShellProps;
  const namespace = '@jarvis/react-ecp-devices-homepage';
  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React);
  const [LDProvider, setLDProvider] = useState(null);

  const getLDProvider = async () => {
    let clientID = '';
    const CURRENT_STACK = currentStack;

    if (CURRENT_STACK.LOCAL) clientID = '6232996cff5a0714a84243fc';
    else if (CURRENT_STACK.PIE) clientID = '6232996cff5a0714a84243ff';
    else if (CURRENT_STACK.STAGE) clientID = '6232996c7927321456ef8f7f';
    else {
      //production
      clientID = '6232996c7927321456ef8f82';
    }

    const Provider = await asyncWithLDProvider({
      clientSideID: clientID,
    });
    return Provider;
  };

  useEffect(() => {
    const setProvider = async () => {
      const Provider = await getLDProvider();

      setLDProvider(() => Provider);
    };

    setProvider();
  }, []);

  return LDProvider ? (
    <section id={namespace}>
      <DirectionProvider direction={directionValue}>
        <ToastProvider>
          <LDProvider>
            <ShellRootProvider
              id={packageInfo.name}
              ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
              stack={props.stack}
              shell={props.shell}
              localization={v1.localization}
              useToast={useToast}
              events={v1.events}
              authProvider={v1.authProvider}
              navigation={v1.navigation}
              getBreadcrumb={v1.breadcrumbs.getBreadcrumbs}
              setBreadcrumb={v1.breadcrumbs.add}
              removeBreadcrumb={v1.breadcrumbs.remove}
              showDevicesMainHeader={props.showDevicesMainHeader ?? true}
              deviceDetailsComponent={props.deviceDetailsComponent ?? null}
              deviceDetailsMfe={props.deviceDetailsMfe ?? ''}
              customRelativePath={props.customRelativePath ?? '/devices'}
              theme={v1.theme}
              featureFlags={null}
            >
              <CustomThemeProvider>
                <Provider store={store}>
                  <App {...props} />
                </Provider>
              </CustomThemeProvider>
            </ShellRootProvider>
          </LDProvider>
        </ToastProvider>
      </DirectionProvider>
    </section>
  ) : (
    <></>
  );
}
