import 'src/styles/global.scss';
import React from 'react';
import SubMfeDeviceDetails from './components/molecule/SubMfeDeviceDetails';
import { setProps } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { GlobalHeader } from 'src/components/atom';
import { useStoreState } from 'src/store/useStoreState';
import { useConstructor } from 'src/hooks/useConstructor';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { getHomepageStoreActions } from 'src/store/homepage/init';
import { DevicesHomepageTemplate } from 'src/components/template';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export const App = (props) => {
  const { homepageState } = useStoreState();
  const { startRootComponent } = homepageState;
  const { navigation, deviceDetailsComponent, customRelativePath } = useShellRootContext();
  const baseurl = customRelativePath || '/devices';
  const baseName = navigation.createHref({ pathname: baseurl });
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  useConstructor(() => {
    setProps();

    dispatchAll(getHomepageStoreActions());
  });

  const didReduxUpdated = () => {
    return startRootComponent;
  };

  const renderDeviceDetailsComponent = () => {
    return deviceDetailsComponent !== null ? (
      deviceDetailsComponent
    ) : (
      <SubMfeDeviceDetails {...props} />
    );
  };

  const renderApp = () => {
    if (didReduxUpdated()) {
      return (
        <Router basename={baseName} data-testid="root-component">
          <Switch>
            <Route exact path="/(|devices|unassigned)">
              <GlobalHeader />
              <DevicesHomepageTemplate {...props} />
            </Route>
            <Route path="/:devID">{renderDeviceDetailsComponent()}</Route>
          </Switch>
        </Router>
      );
    } else {
      return <></>;
    }
  };

  return renderApp();
};
