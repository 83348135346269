import { createContext } from 'react';

const ecpDeviceV55 = null;
const stack = null;
const shell = null;
const localization = null;
const events = null;
const useToast = null;
const authProvider = null;
const navigation = null;
const getBreadcrumb = null;
const setBreadcrumb = null;
const removeBreadcrumb = null;
const showDevicesMainHeader = null;
const deviceDetailsMfe = null;
const deviceDetailsComponent = null;
const customRelativePath = null;
const theme = null;
const featureFlags = null;

const shellRootContext = createContext({
  ecpDeviceV55,
  stack,
  localization,
  shell,
  events,
  useToast,
  authProvider,
  navigation,
  getBreadcrumb,
  setBreadcrumb,
  removeBreadcrumb,
  showDevicesMainHeader,
  deviceDetailsMfe,
  deviceDetailsComponent,
  customRelativePath,
  theme,
  featureFlags,
});

export default shellRootContext;
