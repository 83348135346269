import AssetsProviderFactory from '../assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
};

export const setProps = () => {
  const { authProvider, localization, stack } = useShellRootContext();
  localProps.authProvider = authProvider;
  localProps.language = localization.language;
  localProps.stack = stack ?? 1;
  localProps.country = localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(props.language, props.country);
  return assetsProvider.getText(`ecp-devices-homepage.${subkey}`, args);
};
