import styled from 'styled-components';
import React, { useEffect } from 'react';
import { ProgressIndicator } from '@veneer/core';

type TCommonLoadingProps = {
  height?: string;
};

const CommonLoading = (props: TCommonLoadingProps) => {
  const [render, setRender] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 200);
  }, []);

  return (
    <Wrapper data-testid="hp-loading-section" height={props.height} render={render}>
      <ProgressIndicator />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  min-height: ${(props) => props.height};
  border-radius: 8rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: ${(props) => (props.render ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

CommonLoading.defaultProps = {
  height: '300px',
};

export default CommonLoading;
