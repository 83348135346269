import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { MfeLoader } from 'src/components/atom';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const GlobalHeader = () => {
  const { showDevicesMainHeader, events, localization } = useShellRootContext();
  const { refreshPage } = useRefreshHook();

  const handleReloadPage = () => {
    refreshPage();
  };

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', handleReloadPage);
    return () => {
      events.removeEventListener('ecp-banner-reload-call', handleReloadPage);
    };
  }, [events, handleReloadPage]);

  const SubMfe = useCallback(({ component, type }) => {
    return (
      <MfeLoader
        loadingHeight={'80px'}
        disableContainerPadding
        {...{
          component,
          type,
          localization,
        }}
      />
    );
  }, []);

  const memoGlobalHeader = useMemo(() => {
    return (
      <div className="react-ecp-header-wrapper">
        <SubMfe type="headerTitle" component="@jarvis/react-ecp-header" />
      </div>
    );
  }, []);

  return <>{showDevicesMainHeader && <Wrapper>{memoGlobalHeader}</Wrapper>}</>;
};

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export default GlobalHeader;
