export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm:ss A', // March 17th, 2022 02:45:30 PM (12 hours format)
};

export const sessionStorageKey = {
  serviceID: 'serviceID',
};

export const store = {
  homepage: {
    START_ROOT_COMPONENT: 'homepage/startRootComponent',
    DEVICES_HOMEPAGE_REFRESH: 'homepage/devicesHomepageRefresh',
  },
};

export const service = {
  collection: 'collection',
  collectionStratusPie: 'collectionStratusPie',
  deviceCache: 'deviceCache',
  subscriptions: 'subscriptions',
  programMgtSvc: 'ProgramMgtSvc',
  accountMgtSvc: 'AccountMgtSvc',
};

const origin = {
  ECP_LOCAL: 'http://ecp.local',
  DEV: 'https://ecp.dev.portalshell.int.hp.com',
  PIE: 'https://ecp.pie.portalshell.int.hp.com',
  STAGE: 'https://ecp.stage.portalshell.int.hp.com',
  PROD: 'https://hp-commandcenter.com',
};

export const currentStack = {
  DEV: window.location.origin === origin.DEV,
  LOCAL: window.location.origin.includes(origin.ECP_LOCAL),
  PIE: window.location.origin === origin.PIE,
  STAGE: window.location.origin === origin.STAGE,
  PROD: window.location.origin === origin.PROD,
};
