import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { Tabs } from '@veneer/core';
import { MfeLoader } from 'src/components/atom';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { NavLink, BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export const DevicesHomepageTab = (props) => {
  const { stack, shell, navigation, customRelativePath, getBreadcrumb, removeBreadcrumb } =
    useShellRootContext();
  const { homepageState } = useStoreState();
  const { devicesHomepageRefresh } = homepageState;

  const baseName = navigation.createHref({ pathname: customRelativePath });

  const [currentTab, setCurrentTab] = useState<number | undefined>(getCurrentTab());

  const tabs = [];

  useEffect(() => {
    function tabHandler() {
      setCurrentTab(getCurrentTab());
    }

    window.addEventListener('popstate', tabHandler);
    if (
      getBreadcrumb().at(-1).text != 'Devices' &&
      getBreadcrumb().at(-1).text != 'HP Secure Fleet Manager: Devices'
    ) {
      removeBreadcrumb(getBreadcrumb().at(-1).key);
    }

    return () => window.removeEventListener('popstate', tabHandler);
  }, []);

  function getCurrentTab() {
    const pathArray = navigation.location.pathname.split('/');
    const tabPath = typeof pathArray[4] === 'undefined' ? '' : pathArray[4];

    switch (tabPath) {
      case `unassigned`:
        return 1;
      default:
        return 0;
    }
  }

  const TabMfe = useCallback(
    ({ component, type }) => {
      return (
        <StyledMfeLoader className="devices-homepage-rmc-mfe-loader">
          <MfeLoader
            disableContainerPadding
            {...props}
            {...{
              component,
              type,
              stack,
              shell,
            }}
          />
        </StyledMfeLoader>
      );
    },
    [shell, stack, props],
  );

  tabs.push({
    id: 0,
    label: (
      <StyledNavLink exact to="/" activeClassName="selected" data-testid="assigned-tab">
        {t('Assigned')}
      </StyledNavLink>
    ),
    title: 'Assigned Devices',
    description: '',
    content: (
      <Route path="">
        <TabMfe type="ECPAssignedDevices" component="@jarvis/react-ecp-devices" />
      </Route>
    ),
  });

  // tabs.push({
  //   id: 1,
  //   label: (
  //     <StyledNavLink to="/unassigned" activeClassName="selected" data-testid="unassigned-tab">
  //       {t('Unassigned')}
  //     </StyledNavLink>
  //   ),
  //   title: 'Unassigned Devices',
  //   description: '',
  //   content: (
  //     <Route path="(|/unassigned)">
  //       <TabMfe type="ECPUnassignedDevices" component="@jarvis/react-ecp-unassigned-devices" />
  //     </Route>
  //   ),
  // });

  const memoRenderComponent = useMemo(() => {
    console.log('[devices-homepage] render devices-home-tab');

    return (
      <div data-testid="device-homepage-tab">
        <Router basename={baseName}>
          <Switch>
            {tabs.length !== 0 && (
              <Wrapper>
                <Tabs
                  controlId="extended"
                  mode="extended"
                  selectedTabId={currentTab}
                  tabs={tabs}
                  className={'homepage-tabs'}
                  data-testid="devices-homepage-tabs"
                />
              </Wrapper>
            )}
          </Switch>
        </Router>
      </div>
    );
  }, [shell, stack, currentTab, devicesHomepageRefresh]);

  return <>{memoRenderComponent}</>;
};

const Wrapper = styled.div`
  > div > nav {
    margin-right: 24px;
    margin-left: 24px;
  }
  .homepage-tabs {
    span {
      display: flex;
      justify-content: center;
    }
  }
`;

const StyledMfeLoader = styled.div``;

const StyledNavLink = styled(NavLink)`
  color: black;
  text-decoration: none;

  &.selected {
    color: #027aae;
  }

  &:focus,
  &:hover,
  &:link,
  &:active,
  &:visited {
    text-decoration: none;
  }
`;
