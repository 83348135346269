import React, { useMemo } from 'react';
import { DevicesHomepageTab } from '../organism';

export const DevicesHomepageTemplate = (props) => {
  const memoRenderComponent = useMemo(() => {
    console.log('[devices-homepage] render <DevicesHomepageTab />');

    return <DevicesHomepageTab {...props} />;
  }, []);

  return <>{memoRenderComponent}</>;
};
